export const NXT_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export class DateHelpers {
  public static dateStrIntoIsoDateStr(dateString: string, endOfDay?: boolean) {
    if (!dateString) {
      return '';
    }
    const dateObj = new Date(dateString);
    const timeZoneOffset = -dateObj.getTimezoneOffset() / 60;
    const timeZone = (timeZoneOffset > 0 ? '+' : '') + timeZoneOffset.toString().padStart(2, '0') + ':00';

    if (endOfDay) {
      dateObj.setHours(23, 59, 59);
    }

    return dateObj.toISOString().slice(0, -5) + timeZone;
  }
}
